<template>
  <div class="bg-white shadow p-3 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
      Select Team Members
    </p>
    <p class="font-barlow text-md text-grey-dark ml-2 mb-2">
      Choose team members below and then click Add to Team.
    </p>
    <div class="flex flex-row items-end justify-between">
      <div>
        <InputField
          :placeholder="inputPlaceholder"
          :label="inputLabel"
          :onChange="setKeyword"
          leftIcon
          class="mb-2 w-inputField"
        />
      </div>
      <div class="flex flex-row space-x-2">
        <div class="mb-2">
          <Button
            text="+ Add to Team"
            type="secondary"
            size="medium"
            :disabled="!hasSelected || !teamStatus"
            @click.native="addToTeam"
          />
        </div>
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} employees out of {{ allUsers }}
    </p>
    <div>
      <div
        class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
      >
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" />
        </button>
        <button
          class="flex flex-row items-center w-tableIndex focus:outline-none mr-7"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </button>
        <div class="flex flex-grow max-w-2/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort(0)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/4 ">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort(1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort(2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
            <img
              src="../../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem, total: mappedList.length }"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import uniqBy from "lodash/uniqBy";
import some from "lodash/some";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";

import { mapActions, mapState } from "vuex";

export default {
  name: "UsersTable",
  components: { VirtualList },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: { type: String, default: "Search by name or job title" },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    selectAll: false,
    pageNr: 1,
    sorting: null,
    usersCount: 100,
    hasSelected: false,
  }),
  computed: {
    ...mapState({
      fetchUsers: (state) => state.adminTeams.fetchUsers,
      allUsers: (state) => state.adminTeams.allUsers,
      teamStatus: (state) => state.adminTeams.teamStatus,
    }),
  },
  mounted() {
    this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
    fetchUsers(val) {
      if (val) {
        this.updateList(true);
      }
    },
  },
  methods: {
    ...mapActions({
      getUsersOutsideTeam: "adminTeams/getUsersOutsideTeam",
      assignUsers: "adminTeams/assignUsers",
    }),
    sort(index) {
      this.sorting = null;
      this.pageNr = 0;
      this.mappedList = [];
      this.resetRotationMatrix(index);
      this.rotationMatrix[index] == 1
        ? this.rotationMatrix[index]++
        : this.rotationMatrix[index]--;
      switch (index) {
        case 0:
          this.sorting = { firstName: this.rotationMatrix[index] };
          break;
        case 1:
          this.sorting = { jobtitle: this.rotationMatrix[index] };
          break;
        case 2:
          this.sorting = { email: this.rotationMatrix[index] };
          break;
        default:
          break;
      }
      this.triggerFetch();
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
      this.pageNr = 1;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.allUsers / 10)) {
        this.pageNr++;
        this.selectAll ? this.toggleAll() : null;
        this.updateList();
      }
    },
    addToTeam() {
      const selectedUsers = filter(
        this.mappedList,
        (user) => user.isSelected === true
      );
      selectedUsers.forEach((user) => {
        this.assignUsers({
          teamId: parseInt(this.$route.params.id, 10),
          userId: user.id,
        }).then(() => {
          const index = findIndex(this.mappedList, (i) => i.id === user.id);
          this.removeItem(index);
        });
      });
      this.updateList(true);
    },
    removeItem(index) {
      this.mappedList.splice(index, 1);
      let map = this.mappedList.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: idx,
      }));
      this.mappedList = uniqBy([...map], "id");
      this.pageNr = 1;
    },
    updateList(fetch) {
      if (fetch) {
        this.pageNr = 1;
      }
      const payload = {
        teamId: parseInt(this.$route.params.id, 10),
        pageNumber: this.pageNr,
        pageSize: 10,
        keyword: this.keyword,
        sorting: this.sorting,
      };
      this.getUsersOutsideTeam({ payload }).then((rsp) => {
          let map = rsp.map((item, idx) => ({
              ...item,
            isSelected: false,
            index: (this.pageNr - 1) * 10 + idx,
          }));
          fetch ? this.mappedList = [...map] : this.mappedList = uniqBy([...this.mappedList, ...map], "index");
          this.hasSelected = some(this.mappedList, "isSelected");
      });
    },

    toggleItem(index) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
      this.hasSelected = some(this.mappedList, "isSelected");
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
      this.hasSelected = some(this.mappedList, "isSelected");
    },
  },
};
</script>
