<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-1/2">
        <h1
          class="text-xl text-grey-light font-headers mb-3 font-bold text-grey-dark-3"
        >
          <span class="text-grey-light font-normal"> Teams / </span>
          {{ team.name }}
        </h1>
      </div>
      <div class="flex w-1/2 justify-end">
        <Button
          v-if="!teamStatus"
          text="Activate Team"
          type="primary-white"
          size="medium"
          @click.native="activate()"
        />
        <Button
          v-if="teamStatus"
          text="Deactivate Team"
          type="primary-white"
          size="medium"
          @click.native="deactivate"
        />
      </div>
    </div>
    <div class="pb-3">
      <TeamDetailsCard :team="team" :callback="getTeam" />
    </div>
    <div class="pb-3">
      <MembersTable />
    </div>
    <div>
      <UsersTable :users="userList" :usersCount="team.usersCount" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import TeamDetailsCard from "../components/TeamDetails/TeamDetailsCard";
import MembersTable from "../components/TeamDetails/MembersTable/index";
import UsersTable from "../components/TeamDetails/UsersTable/index";
export default {
  name: "TeamDetailsView",
  components: { TeamDetailsCard, MembersTable, UsersTable },
  async mounted() {
    await this.getTeam();
    await this.getManagers();
  },
  beforeDestroy() {
    this.resetTeam();
  },
  methods: {
    ...mapActions({
      getTeamDetails: "adminTeams/getTeamDetails",
      resetTeam: "adminTeams/resetTeam",
      getManagers: "adminTeams/getManagers",
      openModal: "modals/openModal",
      activateTeamById: "adminTeams/activateTeamById"
    }),
    getTeam() {
      return this.getTeamDetails({ teamId: this.$route.params.id });
    },
    deactivate() {
      this.openModal({ modal: "deleteTeamModal", props: { teamId: parseInt(this.$route.params.id) }  });
    },
    async activate() {
      await this.activateTeamById({ id: parseInt(this.$route.params.id) });
      this.getTeam();
    }
  },
  computed: {
    ...mapState({
      team: (state) => state.adminTeams.team,
      members: (state) => state.adminTeams.members,
      usersCount: (state) => state.adminTeams.usersCount,
      teamStatus: (state) => state.adminTeams.teamStatus,
    }),
    userList() {
      return this.members;
    },
  },
};
</script>

<style>
</style>