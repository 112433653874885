<template>
  <div class="bg-white shadow px-3 pt-3 pb-2 rounded" v-if="team">
    <div class="flex flex-row justify-between">
      <p class="font-barlow text-mdl font-bold text-grey-light mt-1">
        Team Details
      </p>
      <div>
        <div>
          <Button
            text="Edit"
            type="secondary"
            size="medium"
            :class="{'edit-team' : !teamStatus}"
            :iconLeft="edit"
            :disabled="!teamStatus"
            @click.native="editTeam"
          />
        </div>
      </div>
    </div>
    <div class="flex w-4/6 flex-row items-start justify-between ml-2">
      <div class="flex flex-col justify-between">
        <p class="font-barlow text-sm-2 font-normal text-grey-light">Team Name</p>
        <p class="font-medium">{{ team.name }}</p>
      </div>
      <div class="flex flex-col justify-between">
        <p
          class="font-barlow text-sm-2 font-normal text-grey-light flex flex-row"
        >
          <img
            :src="warning"
            width="20"
            height="20"
            class="warning-icon"
            v-if="!team.manager"
          />
          Team Manager
        </p>
        <p class="font-medium">{{ managerFullName }}</p>
      </div>
      <div class="flex flex-col justify-between">
        <p class="font-barlow text-sm-2 font-normal text-grey-light">Members</p>
        <p class="font-medium">{{ membersCount }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/assets/img/icons/edit.svg";
import warning from "@/assets/img/icons/warning.svg";
import { mapActions, mapState } from "vuex";

export default {
  name: "TeamDetailsCard",
  data: () => ({ edit, warning }),
  props: {
    team: {
      type: Object,
      default: null,
    },
    callback: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    ...mapState({
      membersCount: (state) => state.adminTeams.membersCount,
      teamStatus: (state) => state.adminTeams.teamStatus,
    }),
    managerFullName() {
      return this.team.manager
        ? this.team?.manager?.firstName + " " + this.team?.manager?.lastName
        : "No Manager Assigned";
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
    }),
    editTeam() {
      this.openModal({
        modal: "createTeamModal",
        props: { callback: this.callback, team: this.team },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.warning-icon {
  margin-left: -20px;
  padding-right: 5px;
}
.edit-team {
  opacity: 0.5;
}
</style>