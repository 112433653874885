<template>
  <div>
    <div class="flex flex-row w-full py-1 pl-2 items-center">
      <div class="flex w-tableIndex justify-center mr-7 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ source.index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-2/5">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4">
        <p class="text-md text-grey-dark-2">
          {{ source.jobTitle }}
        </p>
      </div>
      <div
        class="flex flex-grow text-md text-grey-dark-2 max-w-1/4 justify-end"
      >
        <Button
          text="Remove from Team"
          type="tertiary"
          size="medium"
          :disabled="status"
          @click.native="removeMember"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'ListItem',
  props: {
    source: { type: Object, default: () => ({}) },
    remove: { type: Function, default: () => null },
    status: { type: Boolean, default: false },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
  },
  methods: {
    ...mapActions({
      openModal: 'modals/openModal',
    }),
    removeMember() {
      this.openModal({
        modal: 'removeMemberModal',
        props: {
          callback: () =>
            this.remove({ id: this.source.id, index: this.source.index }),
        },
      });
    },
  },
};
</script>
