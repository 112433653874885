<template>
  <div class="bg-white shadow p-3 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">Members</p>
    <div v-if="membersCount < 1" class="text-md ml-2">
      <p>Please add members to your team from the list below</p>
    </div>
    <div v-else>
      <div class="flex flex-row items-end justify-between">
        <div>
          <InputField
            :placeholder="inputPlaceholder"
            :label="inputLabel"
            :onChange="setKeyword"
            leftIcon
            class="mb-2 w-inputField"
          />
        </div>
      </div>
      <p class="text-sm-2 text-grey-dark-2 mb-2">
        Showing {{ mappedList.length }} out of {{ membersCount }} team members
      </p>
      <div>
        <div
          class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
          :class="mappedList.length < 10 ? '' : 'pr-2'"
        >
          <button
            class="flex flex-row w-tableIndex items-center focus:outline-none mr-7"
          >
            <p class="text-sm-2 text-grey-light mr-2">NO.</p>
          </button>
          <div class="flex flex-grow max-w-2/5">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="() => sort(0)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
              <img
                src="../../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow max-w-1/4">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sort(1)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
              <img
                src="../../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <button
            class="flex flex-grow max-w-1/4 focus:outline-none justify-end pr-7"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
          </button>
        </div>
        <div>
          <VirtualList
            style="max-height: 360px; overflow-y: auto"
            :data-key="'id'"
            :data-sources="mappedList"
            :data-component="itemComponent"
            :extra-props="{ remove, status: !this.teamStatus }"
            @tobottom="triggerFetch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";

export default {
  name: "MembersTable",
  components: { VirtualList },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: { type: String, default: "Search by name or job title" },
    usersCount: { type: Number },
    users: { type: Array, default: () => [] },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1],
    mappedList: [],
    pageNr: 1,
    sorting: null,
  }),
  mounted() {
    this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
    fetchMembers(val) {
      if (val) {
        this.updateList(true);
      }
    },
  },
  computed: {
    ...mapState({
      membersCount: (state) => state.adminTeams.membersCount,
      fetchMembers: (state) => state.adminTeams.fetchMembers,
      teamStatus: (state) => state.adminTeams.teamStatus,
    }),
  },
  methods: {
    ...mapActions({
      getTeamMembers: "adminTeams/getTeamMembers",
      removeUsers: "adminTeams/removeUsers",
    }),
    remove(data) {
      this.removeUsers({ teamId: this.$route.params.id, userId: data.id }).then(
        () => {
          this.removeItem(data.index);
          this.updateList();
        }
      );
    },
    removeItem(index) {
      this.mappedList.splice(index, 1);
      let map = this.mappedList.map((item, idx) => ({
        ...item,
        index: idx,
      }));
      this.mappedList = uniqBy([...map], "id");
    },
    sort(index) {
      this.sorting = null;
      this.pageNr = 0;
      this.mappedList = [];
      this.resetRotationMatrix(index);
      this.rotationMatrix[index] == 1
        ? this.rotationMatrix[index]++
        : this.rotationMatrix[index]--;
      switch (index) {
        case 0:
          this.sorting = { firstName: this.rotationMatrix[index] };
          break;
        case 1:
          this.sorting = { jobtitle: this.rotationMatrix[index] };
          break;
        default:
          break;
      }
      this.triggerFetch();
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.membersCount / 10)) {
        this.pageNr++;
        this.updateList();
      }
    },
    updateList(fetch) {
      if (fetch) {
        this.pageNr = 1;
      }
      const payload = {
        keyword: this.keyword,
        pageNumber: this.pageNr,
        pageSize: 10,
        sorting: this.sorting,
      };
      this.getTeamMembers({
        teamId: parseInt(this.$route.params.id, 10),
        payload,
      }).then((rsp) => {
        let map = rsp.map((item, idx) => ({
          ...item,
          isSelected: false,
          index: (this.pageNr - 1) * 10 + idx,
        }));
        fetch
          ? (this.mappedList = uniqBy([...map], "id"))
          : (this.mappedList = uniqBy([...this.mappedList, ...map], "id"));
      });
    },
  },
};
</script>
